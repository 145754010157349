import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet"

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";

import CaseList from "../sections/case-study/CaseList1";
import CaseList2 from "../sections/meet-our-customers/CaseList2";
import CTA from "../sections/case-study/CTA";
import Feature1 from "../sections/landing1/Feature";
import Testimonial from "../sections/landing1/Testimonial";

const CaseStudy = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Meet Our Customers</title>
          <meta name="description" content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK." />
        </Helmet>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Title variant="hero">Meet Our Customers</Title>
                <Text>
                  Some text to go here telling the viewer to explore the organisations that we help
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <Feature1
          noTitle
          bg="#FFF"
        />
        <CaseList2 />
        <Testimonial />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudy;
