import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button } from "../../components/Core";
import CaseCard from "../../components/CaseCard";

// @ts-expect-error false positive image import failure
import imgCase1 from "../../assets/image/heros/townlink-small.jpeg";
// @ts-expect-error false positive image import failure
import imgCase2 from "../../assets/image/alert/alert-hand.png";
// @ts-expect-error false positive image import failure
import imgBrand1 from "../../assets/image/jpeg/case-card-brand-img.jpg";
// @ts-expect-error false positive image import failure
import imgBrand2 from "../../assets/image/jpeg/case-card-brand-img-2.jpg";

const CaseList2 = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section bg="#F7F7FB" className="position-relative">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg="6" className="mb-5 mb-lg-0">
            <CaseCard
              img={imgCase1}
              title="ShopWatch &amp; TownLink"
              to="/shopwatch-and-townlink"
            >
              Connecting retailers and licensed premises with the police and CCTV
              through the latest digital radios.
            </CaseCard>
          </Col>
          <Col lg="6" className="mb-5 mb-lg-0">
            <CaseCard
              img={imgCase2}
              title="Secure Information Sharing"
              to="/alert"
            >
              Alert! is the best secure platform for information sharing and messaging 
              between retailers and evening economy.
            </CaseCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CaseList2;
